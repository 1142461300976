import { env } from '@/lib/env';
import { ApiException } from '@/lib/exceptions';
import { auth } from '@/lib/firebase';
import { isError } from '@/lib/utils';
import type { paths } from '@listening/shared';

import { useFeatureFlagStore } from '@/stores/feature-flag-store';
import { logger, useUserStore } from '@listening/shared';
import { signInWithCustomToken } from 'firebase/auth';
import createFetchClient from 'openapi-fetch';
import { z } from 'zod';
import {
  mockSubscriptionSummaryResponses,
  useMockSubStore,
} from './mock-api-responses';

const BASE_URL = env.VITE_BACKEND_URL;
const inlineCommitHash = env.VITE_COMMIT_HASH as string | undefined;

export const safeApiClient = createFetchClient<paths>({ baseUrl: BASE_URL });
safeApiClient.use({
  onRequest({ request }) {
    const token = useUserStore.getState().user?.token;
    if (token) request.headers.set('Authorization', token);
    return request;
  },
});

type RequestProps<P> = {
  path: string;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  token?: string;
  payload?: P;
  params?: Record<string, string | number | boolean | undefined>;
  timeoutMs?: number;
  cache?: RequestCache;
};

function urlCanParse(url: string | URL, base?: string | URL) {
  try {
    new URL(url.toString(), base);
    return true;
  } catch {
    return false;
  }
}

const BackendErrorResponseSchema = z.object({
  error_reason: z.string(),
  error_type: z.string(),
  // status: z.literal('failed'),
  // status_code: z.number(),
});

async function request<P>({
  path,
  method,
  token,
  payload,
  params,
  timeoutMs = 30 * 1000,
  cache = 'reload',
}: RequestProps<P>) {
  if (!urlCanParse(path, BASE_URL)) {
    throw new ApiException(`Invalid path "${path}"`, 'INVALID_URL');
  }

  const url = new URL(path, BASE_URL);

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      if (value === undefined) continue;
      url.searchParams.append(key, String(value));
    }
  }

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Listening-Webapp-Version': inlineCommitHash ?? 'v0',
  };

  if (token) headers.Authorization = token;

  let response: Response;
  const abortController = new AbortController();
  if (timeoutMs)
    setTimeout(() => {
      abortController.abort();
    }, timeoutMs);
  try {
    response = await fetch(url.toString(), {
      headers,
      method,
      body: payload ? JSON.stringify(payload) : undefined,
      signal: abortController.signal,
      cache,
    });
  } catch (error) {
    if (isError(error) && error.name === 'AbortError') {
      throw new ApiException(
        `${method} to ${url.toString()} failed with timeout error`,
        'TIMEOUT',
        undefined,
        error,
      );
    }

    const message = error instanceof Error ? error.message : String(error);
    throw new ApiException(
      `${method} to ${url.toString()} failed with unknown error: ${message}`,
      'FETCH_ERROR',
      undefined,
      error,
    );
  }

  // Raise if status code is not 2XX
  if (response.status >= 400) {
    if (response.status === 401) {
      useUserStore.getState().logout();
      window.location.reload();
    }
    if (response.status === 429)
      throw new ApiException(
        `Too many requests`,
        'TOO_MANY_REQUESTS',
        response,
      );

    const type = response.status <= 499 ? 'CLIENT_ERROR' : 'SERVER_ERROR';
    try {
      const errorResponse = (await response.json()) as unknown;
      const validatedErrorResponse =
        BackendErrorResponseSchema.parse(errorResponse);
      throw new ApiException(
        validatedErrorResponse.error_reason,
        type,
        response,
        undefined,
        validatedErrorResponse,
      );
    } catch (e) {
      if (e instanceof ApiException) throw e;
      if (e instanceof z.ZodError) {
        logger.error(
          'Error response from backend did not match schema',
          e.errors,
        );
      } else {
        logger.error('Error parsing error response from backend', e);
      }
      throw new ApiException(
        `${method} to ${url.toString()} failed with status ${response.status.toString()}`,
        type,
        response,
      );
    }
  }

  // Get the response data
  // Response type should be arbitrary JSON
  type ResponseData = Record<string, unknown>;
  let data: ResponseData | undefined;
  try {
    data =
      response.headers.get('content-type') == 'application/json'
        ? ((await response.json()) as typeof data)
        : undefined;
  } catch {
    throw new ApiException(
      `${method} to ${url.toString()} returned invalid JSON response"`,
      'INVALID_JSON',
    );
  }

  return data;
}

const get = async <P>(props: Omit<RequestProps<P>, 'method' | 'payload'>) =>
  request({ method: 'GET', ...props });
const post = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'POST', ...props });
const deleteReq = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'DELETE', ...props });
const patch = async <P>(props: Omit<RequestProps<P>, 'method'>) =>
  request({ method: 'PATCH', ...props });

export type PaymentDetailsDTO =
  | {
      nextPaymentDate: string;
      status: 'success';
    }
  | {
      status: 'failed';
    };

export type LoginDTO = {
  Authorization: string;
  email: string;
  firebase_custom_token: string | undefined;
  podcastURL: string;
  remainingCredits: number;
  spotifyURL: string;
  status: string;
} & ({ id: string } | { _id: string });

export async function loginReq(
  email: string,
  password: string,
): Promise<LoginDTO> {
  try {
    const result = await post({
      path: '/user/login',
      payload: {
        email,
        password,
      },
    });
    return result as LoginDTO;
  } catch (error) {
    if (ApiException.fromError(error)?.response?.status === 400) {
      throw new ApiException('Invalid email or password', 'CLIENT_ERROR');
    }
    throw error;
  }
}

export type RegisterDTO = LoginDTO & {
  random_password?: string;
  firebase_custom_token: string;
  client_secret?: string;
  customer_id?: string;
};
export async function registerUserReq({
  email,
  utmParams,
  price_id,
  trial_period_days,
  promo_code,
  password,
  random_password,
  firebase_id_token,
}: {
  email: string;
  utmParams: Record<string, string | undefined>;
  price_id?: string;
  trial_period_days?: number;
  promo_code?: string | null;
  random_password: boolean;
  password?: string;
  firebase_id_token?: string;
}) {
  const results = await post({
    path: '/user/register',
    payload: {
      ...utmParams,
      email,
      password,
      price_id,
      trial_period_days,
      promo_code,
      firebase_id_token,
    },
    params: {
      ...utmParams,
      random_password,
    },
  });
  if (results?.status == 'failed')
    throw new ApiException(
      typeof results.message === 'string'
        ? results.message
        : 'Failed to register user',
      'CLIENT_ERROR',
    );

  return results as RegisterDTO;
}

export function deleteAccountReq(token: string): Promise<LoginDTO> {
  return post({
    path: '/user/deleteUser',
    token,
  }) as Promise<LoginDTO>;
}

type PdfUploadUrlDTO = {
  signed_upload_url: string;
  fields: Record<string, string>;
};

export function createPdfUploadUrlReq(
  filename: string,
  token: string,
): Promise<PdfUploadUrlDTO> {
  logger.log(`Creating upload url for ${filename}`);
  return post({
    path: '/pdf/get-upload-url',
    payload: { filename },
    token,
  }) as unknown as Promise<PdfUploadUrlDTO>;
}

export async function uploadFileToS3Req(
  file: File,
  signedUrl: string,
  fields: Record<string, string>,
  onProgress?: (progress: number) => void,
  timeoutMs?: number,
): Promise<string> {
  logger.log(`Uploading file ${file.name} to ${signedUrl}`);

  const formData = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }
  formData.append('file', file);

  const xhr = new XMLHttpRequest();

  let timeoutId: number | null = null;

  xhr.upload.onprogress = (event) => {
    if (!event.lengthComputable) return;
    const progress = Math.round((event.loaded * 100) / event.total);
    if (onProgress) onProgress(progress);

    if (timeoutId) clearTimeout(timeoutId);
    if (timeoutMs)
      timeoutId = setTimeout(() => {
        xhr.abort();
        throw new Error('Upload timed out');
      }, timeoutMs);
  };

  xhr.open('POST', signedUrl, true);

  const resp = await new Promise<XMLHttpRequest>((resolve, reject) => {
    xhr.onload = () => {
      resolve(xhr);
    };
    xhr.onerror = (progress) => {
      reject(
        new Error('XMLHttpRequest error', {
          cause: {
            xhr,
            progress,
          },
        }),
      );
    };
    xhr.send(formData);
  });
  if ((timeoutId as number | null) != null)
    clearTimeout(timeoutId as unknown as number);

  const locationHeader = resp.getResponseHeader('location');
  if (!locationHeader) throw new Error('No location header in response');
  return locationHeader;
}

export type ProcessingItemStatus =
  | 'initializing'
  | 'incomplete'
  | 'downloadedPDF'
  | 'deleted'
  | 'completedExtraction'
  | 'complete'
  | 'Error'
  | 'Error_Not_Enough_Credit';
export type ProcessingQueueItemDTO = {
  id: string;
  status: ProcessingItemStatus;
  title?: string;
};
export function getProcessingQueueReq(token: string): Promise<{
  processingQueue: ProcessingQueueItemDTO[];
}> {
  return get({
    path: '/audio/getProcessingQueue',
    token,
  }) as unknown as Promise<{
    processingQueue: ProcessingQueueItemDTO[];
  }>;
}

export type UserProfileDTO = {
  first_name?: string;
  last_name?: string;
};
export function getUserProfileReq(
  token: string,
): Promise<{ data: UserProfileDTO }> {
  return get({
    path: '/user/profile',
    token,
  }).catch((error: unknown) => {
    if (error instanceof ApiException && error.response?.status === 404) {
      useUserStore.getState().logout();
      window.location.reload();
    }
    throw error;
  }) as unknown as Promise<{ data: UserProfileDTO }>;
}

export function patchUserProfileReq(
  token: string,
  changes: Partial<UserProfileDTO>,
): Promise<{ data: UserProfileDTO }> {
  return patch({
    path: '/user/profile',
    token,
    payload: {
      updates: changes,
    },
  }) as unknown as Promise<{ data: UserProfileDTO }>;
}

export type ListeningVoice = 'AMY' | 'DAN' | 'LIV' | 'SCARLETT' | 'WILL';
export type NoteCaptureLength = 'sentence' | 'paragraph';
export type UserPreferencesDTO = {
  voice: ListeningVoice;
  audio_note_capture: NoteCaptureLength;
};
export async function getUserPreferencesReq(
  token: string,
): Promise<UserPreferencesDTO> {
  const results = (await get({
    path: '/user/preferences',
    token,
  })) as unknown as UserPreferencesDTO;
  return {
    ...results,
    audio_note_capture:
      (results.audio_note_capture as NoteCaptureLength | undefined | null) ??
      (localStorage.getItem('audio_note_capture') as
        | 'sentence'
        | 'paragraph'
        | null) ??
      'sentence',
  };
}

export function updateUserPreferencesReq(
  token: string,
  updates: Partial<UserPreferencesDTO>,
): Promise<{ data: UserPreferencesDTO }> {
  localStorage.setItem('audio_note_capture', updates.audio_note_capture ?? '');
  return post({
    path: '/user/preferences',
    token,
    payload: {
      updates,
    },
  }) as unknown as Promise<{ data: UserPreferencesDTO }>;
}

export type Note = {
  audio_conversion_id: string;
  body: string;
  created_at_ms?: number;
  deleted_at_ms?: number;
  id: string;
  title: string;
  updated_at_ms?: number;
  user_id: string;
  chapter_id?: string;
  chapter_audio_offset_ms?: number;
};

export type ListNotesReqOptions = {
  audioConversionId?: string;
  limit?: number;
  cursorId?: string | null;
  includeDeleted?: boolean;
};

export async function listNotesReq(
  token: string,
  opts?: ListNotesReqOptions,
): Promise<{ notes: Note[]; cursorId: string | null }> {
  const result = (await get({
    path: '/notes/',
    token,
    params: {
      audio_conversion_id: opts?.audioConversionId,
      cursor_id: opts?.cursorId ? opts.cursorId : undefined,
      include_deleted: opts?.includeDeleted,
      limit: opts?.limit,
    },
  })) as unknown as {
    data: {
      notes: Note[];
      cursor_id?: string;
    };
  };

  return {
    notes: result.data.notes,
    cursorId: result.data.cursor_id ?? null,
  };
}

export function upsertNotesReq(
  token: string,
  notes: Omit<Note, 'id'>[],
): Promise<{
  data: Note[];
}> {
  return post({
    path: '/notes/upsert-many',
    token,
    payload: {
      notes,
    },
  }) as unknown as Promise<{
    data: Note[];
  }>;
}

export async function deleteNoteReq({
  token,
  noteId,
}: {
  token: string;
  noteId: string;
}): Promise<void> {
  await deleteReq({
    path: `/notes/${noteId}`,
    token,
  });
}

export async function modifyStripeSubscriptionReq({
  token,
  stripePriceId,
}: {
  token: string;
  stripePriceId: string;
}): Promise<void> {
  await post({
    path: '/stripe/modify-subscription',
    payload: {
      stripe_price_id: stripePriceId,
    },
    token,
  });
}

export type SubscriptionSummaryDTO =
  paths['/payment/subscriptions']['get']['responses']['200']['content']['application/json'];
export async function getSubscriptionsReq(
  token: string,
): Promise<SubscriptionSummaryDTO> {
  const chosenMock = useMockSubStore.getState().chosenMock;
  const shouldMock =
    useFeatureFlagStore.getState().flags.MOCK_SUBSCRIPTION_ENDPOINT &&
    chosenMock !== 'mock_disabled';
  if (shouldMock) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return mockSubscriptionSummaryResponses[chosenMock];
  }
  return get({
    path: '/payment/subscriptions',
    token,
  }) as unknown as Promise<SubscriptionSummaryDTO>;
}

export type UserSummaryDTO = {
  email: string;
  id: string;
  metering: {
    is_active_subscription: boolean;
    has_active_stripe_subscription: boolean;
  };
  onboarding: {
    is_complete: boolean;
  };
  firebase_custom_token: string | undefined;
};

export async function getUserSummaryReq(
  token: string,
): Promise<UserSummaryDTO> {
  const chosenMock = useMockSubStore.getState().chosenMock;
  const shouldMock =
    useFeatureFlagStore.getState().flags.MOCK_SUBSCRIPTION_ENDPOINT &&
    chosenMock !== 'mock_disabled';

  const data = (await get({
    path: '/user/summary',
    token,
  })) as unknown as UserSummaryDTO;
  const firebaseUser = auth.currentUser;
  if (!firebaseUser) {
    try {
      if (data.firebase_custom_token) {
        await signInWithCustomToken(auth, data.firebase_custom_token);
      }
    } catch (error) {
      logger.error('Could not sign in with custom token on init', error);
    }
  }
  if (shouldMock) {
    const mockIsValid = useMockSubStore.getState().isSubscriptionValid;
    return {
      ...data,
      metering: {
        ...data.metering,
        is_active_subscription: mockIsValid,
      },
    };
  }
  return data;
}
export async function getPaymentDetailsReq(token: string) {
  const chosenMock = useMockSubStore.getState().chosenMock;
  const nextPaymentMock = useMockSubStore.getState().mockNextPaymentDate;
  const shouldMock =
    useFeatureFlagStore.getState().flags.MOCK_SUBSCRIPTION_ENDPOINT &&
    chosenMock !== 'mock_disabled' &&
    nextPaymentMock;
  if (shouldMock) {
    return {
      nextPaymentDate: nextPaymentMock.toISOString(),
      status: 'success',
    };
  }

  return get({
    path: '/money/getPaymentDetails',
    token,
  }) as unknown as Promise<PaymentDetailsDTO>;
}

export async function cancelStripeSubscriptionReq(token: string) {
  await post({
    path: '/stripe/cancel-subscription',
    token,
  });
}

export async function postCancelSurveyReq(
  token: string,
  survey: {
    email: string;
    is_user_deleted: boolean;
    user_cancel_reason: string;
    user_liked_features: string;
  },
) {
  await post({
    payload: {
      data: survey,
    },
    path: '/user/post-cancel-survey',
    token,
  });
}

export async function modifyStripePaymentMethodReq({
  token,
  paymentMethodId,
}: {
  token: string;
  paymentMethodId: string;
}) {
  await post({
    path: '/stripe/modify-payment-method',
    payload: {
      payment_method_id: paymentMethodId,
    },
    token,
  });
}

export async function resubscribeStripeSubscriptionReq({
  token,
  price_id,
}: {
  token: string;
  price_id: string;
}) {
  return (await post({
    path: '/stripe/resubscribe',
    token,
    payload: {
      price_id,
    },
  })) as unknown as Promise<{ payment_intent_client_secret: string | null }>;
}

export async function successfulPaymentIntentReq({
  token,
  payment_intent_id,
}: {
  token: string;
  payment_intent_id: string;
}) {
  await post({
    path: '/stripe/successful-payment-intent',
    token,
    payload: {
      payment_intent_id,
    },
  });
}

export async function reportMisPronunciationReq({
  token,
  payload,
}: {
  token: string;
  payload: {
    report_type: 'pronunciation' | 'citation';
    user_message: string;
    audio_conversion_id?: string;
    paragraph_index?: number;
    audio_url?: string;
    chapter_id?: string;
    raw_text?: string;
  };
}) {
  await post({
    token,
    path: '/user/feedback',
    payload: {
      feedback: payload,
    },
  });
}
