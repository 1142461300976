import { logger } from '@listening/shared';
import {
  infiniteQueryOptions,
  queryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useCallback } from 'react';
import { throttle } from 'throttle-debounce';
import { safeApiClient } from './api-service';

import type { components, paths } from '@listening/shared';
import { useEffect, useRef, useState } from 'react';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

import type { InfiniteData, QueryClient } from '@tanstack/react-query';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';
import type { FontSettings } from './read-along.service';

type AudioStoreState = {
  audioItemId?: string;
  chapterId?: string;
  setAudioItem: (audioItemId?: string, chapterId?: string) => void;
  setChapter: (chapterId: string) => void;
  setShuffle: (shuffle: boolean) => void;
  shuffle: boolean;
};

const useAudioStore = create<AudioStoreState>()(
  devtools((set) => ({
    audioItemId: undefined,
    chapterId: undefined,
    setAudioItem: (audioItemId?: string, chapterId?: string) => {
      if (!audioItemId) {
        set({ audioItemId: undefined, chapterId: undefined });
        return;
      }
      set({ audioItemId, chapterId });
    },
    setChapter: (chapterId: string) => {
      set({ chapterId });
    },
    setShuffle: (shuffle: boolean) => {
      set({ shuffle });
    },
    shuffle: false,
  })),
);

const useCurrentAudio = () => {
  const audioItemId = useAudioStore((state) => state.audioItemId);
  const query = useAudioQuery(audioItemId);

  return { query, audioItemId };
};

const useCurrentChapter = () => {
  const chapterId = useAudioStore((state) => state.chapterId);
  const audioItemId = useAudioStore((state) => state.audioItemId);
  const query = useChapterQuery(
    chapterId && audioItemId ? { chapterId, audioId: audioItemId } : undefined,
  );

  return { query, chapterId };
};

type LocalPrefsState = {
  setPlaybackRate: (playbackRate: number) => void;
  playbackRate: number;
  getVolume: () => number;
  setVolume: (volume: number) => void;
  _volume: number;
  muted: boolean;
  setMuted: (muted: boolean) => void;
  setFontSettings: (settings: FontSettings) => void;
  fontSettings: FontSettings;
  reset: () => void;
  readAlongViewer: 'normal' | 'pdf';
  setReadAlongViewer: (viewer: 'normal' | 'pdf') => void;
  pdfScale: number;
  setPdfScale: (scale: number) => void;
  autoScroll: boolean;
  setAutoScroll: (autoScroll: boolean) => void;
  homeScreenLayout: 'grid' | 'list';
  setHomeScreenLayout: (layout: 'grid' | 'list') => void;
  hiddenInCompleteConversions: string[];
  hideInCompleteConversion: (audioId: string) => void;
  erroredAudioIds: string[];
  addErroredAudioId: (audioId: string) => void;
};

const useLocalPrefsStore = create<LocalPrefsState>()(
  persist(
    (set, get) => ({
      setPlaybackRate: (playbackRate: number) => {
        set({ playbackRate });
      },
      playbackRate: 1,
      getVolume: () => {
        if (get().muted) return 0;
        return get()._volume;
      },
      setVolume: (volume: number) => {
        if (volume == 0) set({ muted: true });
        else set({ muted: false });
        set({ _volume: volume });
      },
      _volume: 1,
      muted: false,
      setMuted: (muted: boolean) => {
        set({ muted });
      },
      setFontSettings: (settings: FontSettings) => {
        set({ fontSettings: settings });
      },
      fontSettings: {
        size: 'Medium',
        style: 'Poppins',
      },
      reset: () => {
        set({
          playbackRate: 1,
          _volume: 1,
          muted: false,
          fontSettings: {
            size: 'Medium',
            style: 'Poppins',
          },
          hiddenInCompleteConversions: [],
        });
      },
      readAlongViewer: 'pdf',
      setReadAlongViewer: (viewer: 'normal' | 'pdf') => {
        set({ readAlongViewer: viewer });
      },
      pdfScale: 1,
      setPdfScale: (scale: number) => {
        if (scale < 0.2) return;
        if (scale > 5) return;
        set({ pdfScale: scale });
      },
      autoScroll: false,
      setAutoScroll: (autoScroll: boolean) => {
        set({ autoScroll });
      },
      homeScreenLayout: 'grid',
      setHomeScreenLayout: (layout: 'grid' | 'list') => {
        set({ homeScreenLayout: layout });
      },
      hiddenInCompleteConversions: [],
      hideInCompleteConversion: (audioId: string) => {
        set({
          hiddenInCompleteConversions: [
            ...get().hiddenInCompleteConversions,
            audioId,
          ],
        });
      },
      erroredAudioIds: [],
      addErroredAudioId: (audioId: string) => {
        const MAX_ERRORED_IDS = 300;
        const erroredAudioIds = get().erroredAudioIds;
        set({
          erroredAudioIds: [
            ...erroredAudioIds.slice(-MAX_ERRORED_IDS + 1),
            audioId,
          ],
        });
      },
    }),
    {
      name: 'audio-prefs-storage',
    },
  ),
);
export type AudioSummary = components['schemas']['AudioConversionSummary'];
export type AudioDetail = components['schemas']['AudioConversionDetail'];
export type ChapterDetail = components['schemas']['ChapterDetail'];

const listAudioQueryKeyPrefix = ['audio', 'summary'];

function listAudioQueryOpts(
  opts?: paths['/v2/audio']['get']['parameters']['query'],
  fetchOptions?: {
    staleTime?: number;
    refetchInterval?: number;
  },
) {
  return infiniteQueryOptions({
    queryKey: [...listAudioQueryKeyPrefix, opts],
    queryFn: async ({ pageParam }) => {
      const resp = await safeApiClient.GET('/v2/audio', {
        params: {
          query: {
            status: 'complete',
            cursor: pageParam,
            ...opts,
          },
        },
      });
      if (!resp.data) throw new Error('Failed to fetch audio items');
      return resp.data;
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: '',
    staleTime: fetchOptions?.staleTime,
    refetchInterval: fetchOptions?.refetchInterval,
  });
}

function getAudioFromListCache(opts: {
  audioId: string;
  queryClient: QueryClient;
}) {
  const listQueries = opts.queryClient.getQueriesData({
    queryKey: listAudioQueryKeyPrefix,
  });

  const queriesCache = listQueries.map(
    ([, queryData]) =>
      queryData as
        | InfiniteData<{
            audio_conversions: components['schemas']['AudioConversionSummary'][];
            cursor: string | null;
          }>
        | undefined,
  );

  const audio = queriesCache
    .map((cache) => cache?.pages ?? [])
    .flat()
    .map((page) => page.audio_conversions)
    .flat()
    .find((audio) => audio.id == opts.audioId);

  return audio;
}

function fetchAudioQueryOpts(opts: {
  audioId?: string;
  queryClient: QueryClient;
}) {
  return queryOptions({
    enabled: !!opts.audioId,
    queryKey: ['audio', 'single-summary', opts.audioId, opts.queryClient],
    queryFn: async (): Promise<AudioDetail | AudioSummary> => {
      if (!opts.audioId) throw new Error('No audio ID provided');
      const audioFromListCache = getAudioFromListCache({
        audioId: opts.audioId,
        queryClient: opts.queryClient,
      });
      if (audioFromListCache) return audioFromListCache;

      const resp = await safeApiClient.GET('/v2/audio/{audio_id}', {
        params: {
          path: {
            audio_id: opts.audioId,
          },
        },
      });
      if (!resp.data) throw new Error('Failed to fetch audio summary');
      return resp.data.audio_conversion;
    },
  });
}

function listChapterQueryOpts(audio_id?: string) {
  return infiniteQueryOptions({
    enabled: !!audio_id,
    staleTime: 1000 * 60 * 15,
    queryKey: ['chapters', audio_id],
    queryFn: async ({ pageParam }) => {
      if (!audio_id) throw new Error('No audio ID provided');
      const resp = await safeApiClient.GET('/v2/audio/{audio_id}/chapters', {
        params: {
          query: {
            cursor: pageParam,
          },
          path: {
            audio_id,
          },
        },
      });
      if (!resp.data) throw new Error('Failed to fetch audio items');
      return resp.data;
    },
    getNextPageParam: (lastPage) => lastPage.cursor,
    initialPageParam: '',
  });
}

function fetchChapterQueryOpts(opts: {
  queryClient: QueryClient;
  chapter?: {
    chapterId: string;
    audioId: string;
  };
}) {
  const { queryClient, chapter } = opts;
  return queryOptions({
    enabled: !!chapter,
    queryKey: ['chapter', chapter],
    queryFn: async () => {
      if (!chapter) throw new Error('No chapter ID provided');
      const { chapterId, audioId } = chapter;
      const chapterListPages = queryClient.getQueryData(
        listChapterQueryOpts(audioId).queryKey,
      );
      const cachedChapter = chapterListPages?.pages
        .map((page) => page.chapters)
        .flat()
        .find((c) => c.id === chapterId);
      if (cachedChapter) return cachedChapter;

      const resp = await safeApiClient.GET('/v2/chapter/{chapter_id}', {
        params: {
          path: {
            chapter_id: chapterId,
          },
        },
      });
      if (!resp.data) throw new Error('Failed to fetch chapter');
      return resp.data.chapter;
    },
  });
}

const useListAudioQuery = (
  opts?: paths['/v2/audio']['get']['parameters']['query'],
) => useInfiniteQuery(listAudioQueryOpts(opts));

const useChapterQuery = (chapter?: { audioId: string; chapterId: string }) => {
  const queryClient = useQueryClient();
  return useQuery(fetchChapterQueryOpts({ chapter, queryClient }));
};

const useAudioQuery = (audioId?: string) => {
  const queryClient = useQueryClient();
  return useQuery(
    fetchAudioQueryOpts({
      audioId,
      queryClient,
    }),
  );
};

const DEFAULT_TITLE = 'Untitled';

function useAudioTimeSec() {
  const frameRef = useRef<number>();
  const [pos, setPos] = useState(0);
  const { getPosition } = useGlobalAudioPlayer();

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;
    const animate = () => {
      setPos(getPosition());
      timeoutId = setTimeout(() => {
        frameRef.current = requestAnimationFrame(animate);
      }, 100);
    };

    frameRef.current = window.requestAnimationFrame(animate);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [getPosition]);

  return pos;
}

function processingItemStatusString(audio: AudioSummary) {
  if (audio.conversion_status == 'initializing') return 'Initializing';
  if (audio.conversion_status == 'completedExtraction')
    return 'Generating Audio';
  if (audio.conversion_status == 'Error')
    return audio.error ? audio.error : 'An error occurred';
  if (audio.conversion_status == 'complete') return 'Completed';
  if (audio.conversion_status == 'deleted') return 'Deleted';
}

const continueListeningQueryOpts = listAudioQueryOpts(
  {
    sort_by: 'played_at',
    limit: 10,
  },
  {
    staleTime: 1000 * 35,
    refetchInterval: 1000 * 35,
  },
);
const useContinueListeningAudioItems = () => {
  const query = useInfiniteQuery(continueListeningQueryOpts);
  return (
    query.data?.pages
      .map((p) => p.audio_conversions)
      .flat()
      .filter((a) => a.progress && a.progress.timestamp_ms != 0) ?? []
  ).slice(0, 10);
};

const sessionConversionHistoryAtom = atomWithStorage<AudioSummary[]>(
  'sessionConversionHistoryAtom',
  [],
);

const isTimestampActive = (
  currentTime: number,
  timestamp_ms: { start: number; end: number } | null,
) => {
  if (!timestamp_ms) return false;
  const currentMs = currentTime * 1000;
  return currentMs >= timestamp_ms.start && currentMs < timestamp_ms.end;
};

const useIsLabelActive = () => {
  const chapter = audioService.store.useCurrentChapter().query.data;

  return useCallback(
    (
      currentTime: number,
      chapterId: string,
      timestamp_ms: { start: number; end: number } | null,
    ) => {
      return (
        chapter &&
        chapter.id === chapterId &&
        isTimestampActive(currentTime, timestamp_ms)
      );
    },
    [chapter],
  );
};

const useActiveText = (level: 'sentence' | 'paragraph') => {
  const chapterQuery = useCurrentChapter().query;
  const time = useAudioTimeSec();

  if (!chapterQuery.data) return null;

  const activeParagraph = chapterQuery.data.paragraphs.find((p) =>
    isTimestampActive(time, p.timestamp_ms),
  );
  if (level === 'sentence') {
    const activeSentence = activeParagraph?.sentences.find((s) =>
      isTimestampActive(time, s.timestamp_ms),
    );
    const activeSentenceText =
      activeSentence?.words.map((w) => w.text).join(' ') ?? null;
    return activeSentenceText;
  }
  const activeParagraphText =
    activeParagraph?.sentences
      .map((s) => s.words.map((w) => w.text).join(' '))
      .join(' ') ?? null;
  return activeParagraphText;
};

const useCreateAudioMutation = () => {
  const queryClient = useQueryClient();
  const [, setHistory] = useAtom(sessionConversionHistoryAtom);

  return useMutation({
    mutationFn: async (url: string) => {
      const response = await safeApiClient.POST('/v2/audio', {
        body: {
          document_url: url,
        },
      });
      if (!response.data?.audio_conversion)
        throw new Error('No audio conversion data');
      return response.data.audio_conversion;
    },
    onSuccess: async (audioConversion, url) => {
      logger.log(`Successfully requested converted url`, url);
      setHistory((history) => [...history, audioConversion]);
      await queryClient.invalidateQueries({
        queryKey: listAudioQueryKeyPrefix,
      });
    },
    onError: (error) => {
      logger.error(error);
    },
  });
};

const useDeleteAudioMutation = () => {
  const queryClient = useQueryClient();
  const audioStore = useAudioStore();
  const audioPlayer = useGlobalAudioPlayer();
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  return useMutation({
    mutationFn: async (audioItemId: string) => {
      const resp = await safeApiClient.DELETE('/v2/audio/{audio_id}', {
        params: {
          path: {
            audio_id: audioItemId,
          },
        },
      });
      if (!resp.response.ok) throw new Error('Failed to delete audio item');
    },
    onSuccess: (_, audioId) => {
      logger.log('Deleted audio item successfully');

      const cleanup = () => {
        if (audioStore.audioItemId == audioId) {
          audioStore.setAudioItem(undefined);
          audioPlayer.stop();
        }
        return queryClient.invalidateQueries({
          queryKey: listAudioQueryKeyPrefix,
        });
      };
      const match = matchRoute({
        to: '/readalong/$audioId/$chapterId',
        params: {
          audioId,
        },
        fuzzy: true,
      });
      if (match) {
        logger.log('Navigating to home screen from deleted audio');
        return navigate({
          to: '/home',
        }).then(cleanup);
      } else {
        return cleanup();
      }
    },
  });
};

const useUpdateAudioMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      updates,
    }: {
      id: string;
      updates: NonNullable<
        paths['/v2/audio/{audio_id}']['patch']['requestBody']
      >['content']['application/json'];
    }) => {
      const resp = await safeApiClient.PATCH('/v2/audio/{audio_id}', {
        params: {
          path: {
            audio_id: id,
          },
        },
        body: updates,
      });
      if (!resp.data) throw new Error('Failed to update audio item');
      return resp.data.audio_conversion;
    },
    onSuccess: () => {
      logger.log('Updated audio item successfully');
      return queryClient.invalidateQueries({
        queryKey: listAudioQueryKeyPrefix,
      });
    },
  });
};

const postAudioProgressThrottled = throttle(
  5000,
  async ({
    chapter_id,
    timestamp_ms,
  }: {
    chapter_id: string;
    timestamp_ms: number;
  }) => {
    await safeApiClient.PUT('/v2/chapter/{chapter_id}/progress', {
      params: {
        path: { chapter_id },
      },
      body: {
        timestamp_ms: Math.round(timestamp_ms),
      },
    });
    console.log('Throttled progress updated', chapter_id, timestamp_ms);
  },
);

const useListChaptersQuery = (audioId?: string) =>
  useInfiniteQuery(listChapterQueryOpts(audioId));

const useListAllChaptersQuery = (audioId?: string) => {
  const listQuery = useListChaptersQuery(audioId);

  if (listQuery.hasNextPage && !listQuery.isFetchingNextPage) {
    void listQuery.fetchNextPage();
  }

  return listQuery;
};

async function iterateChaptersListUntil({
  queryClient,
  audioId,
  until,
}: {
  queryClient: QueryClient;
  audioId: string;
  until: (chapterBuffer: ChapterDetail[]) => boolean;
}) {
  const scanData = async (cursor: string | null) => {
    const chaptersList = await queryClient.ensureInfiniteQueryData({
      ...listChapterQueryOpts(audioId),
      initialPageParam: cursor ?? '',
    });
    const chaptersBuffer = chaptersList.pages
      .map((page) => page.chapters)
      .flat();

    if (until(chaptersBuffer)) return chaptersBuffer;
    const lastPage = chaptersList.pages[chaptersList.pages.length - 1];
    if (!lastPage) return [];
    if (lastPage.cursor === null) return chaptersBuffer;
    return scanData(lastPage.cursor);
  };
  return scanData(null);
}

async function iterateAudioListUntil({
  queryClient,
  until,
}: {
  queryClient: QueryClient;
  until: (audioBuffer: AudioSummary[]) => boolean;
}) {
  const scanData = async (cursor: string | null) => {
    const audioList = await queryClient.ensureInfiniteQueryData({
      ...listAudioQueryOpts(),
      initialPageParam: cursor ?? '',
    });
    const audioBuffer = audioList.pages
      .map((page) => page.audio_conversions)
      .flat();
    if (until(audioBuffer)) return audioBuffer;
    const lastPage = audioList.pages[audioList.pages.length - 1];
    if (!lastPage) return [];
    if (lastPage.cursor === null) return audioBuffer;
    return scanData(lastPage.cursor);
  };
  return scanData(null);
}

async function findNextChapter({
  queryClient,
  audioId,
  chapterId,
}: {
  queryClient: QueryClient;
  audioId: string;
  chapterId: string;
}) {
  const chaptersBuffer = await iterateChaptersListUntil({
    queryClient,
    audioId,
    until: (chapters) => {
      const currentIdx = chapters.findIndex((c) => c.id === chapterId);
      if (currentIdx === -1) return false;
      return currentIdx + 1 < chapters.length;
    },
  });
  const currentIdx = chaptersBuffer.findIndex((c) => c.id === chapterId);
  if (currentIdx === -1) return null;
  if (currentIdx + 1 >= chaptersBuffer.length) return null;
  return chaptersBuffer[currentIdx + 1];
}

async function findPreviousChapter({
  queryClient,
  audioId,
  chapterId,
}: {
  queryClient: QueryClient;
  audioId: string;
  chapterId: string;
}) {
  const chaptersBuffer = await iterateChaptersListUntil({
    queryClient,
    audioId,
    until: (chapters) => {
      const currentIdx = chapters.findIndex((c) => c.id === chapterId);
      if (currentIdx === -1) return false;
      return true;
    },
  });
  const currentIdx = chaptersBuffer.findIndex((c) => c.id === chapterId);
  if (currentIdx === -1) return null;
  if (currentIdx === 0) return null;
  return chaptersBuffer[currentIdx - 1];
}

async function findNextAudio({
  queryClient,
  audioId,
}: {
  queryClient: QueryClient;
  audioId?: string;
}) {
  const audioBuffer = await iterateAudioListUntil({
    queryClient,
    until: (audioBuffer) => {
      if (!audioId) return true;
      const currentIdx = audioBuffer.findIndex((a) => a.id === audioId);
      if (currentIdx === -1) return false;
      return currentIdx + 1 < audioBuffer.length;
    },
  });
  if (!audioId) return audioBuffer[0];
  const currentIdx = audioBuffer.findIndex((a) => a.id === audioId);
  if (currentIdx === -1) return null;
  if (currentIdx + 1 >= audioBuffer.length) return null;
  return audioBuffer[currentIdx + 1];
}

async function findPreviousAudio({
  queryClient,
  audioId,
}: {
  queryClient: QueryClient;
  audioId?: string;
}) {
  const audioBuffer = await iterateAudioListUntil({
    queryClient,
    until: (audioBuffer) => {
      if (!audioId) return true;
      const currentIdx = audioBuffer.findIndex((a) => a.id === audioId);
      if (currentIdx === -1) return false;
      return true;
    },
  });
  if (!audioId) return audioBuffer[0];
  const currentIdx = audioBuffer.findIndex((a) => a.id === audioId);
  if (currentIdx === -1) return null;
  if (currentIdx === 0) return null;
  return audioBuffer[currentIdx - 1];
}

export const audioService = {
  queryOpts: {
    listAudioQueryKeyPrefix,
    listAudioQueryOpts,
    fetchAudioQueryOpts,
    listChapterQueryOpts,
    fetchChapterQueryOpts,
  },
  primaryQueries: {
    useListAudioQuery,
    useAudioQuery,
    useListChaptersQuery,
    useListAllChaptersQuery,
    useChapterQuery,
  },
  secondaryQueries: {
    useContinueListeningAudioItems,
  },
  mutations: {
    useDeleteAudioMutation,
    useUpdateAudioMutation,
    useCreateAudioMutation,
  },
  player: {
    useAudioTimeSec,
    useIsLabelActive,
    useActiveText,
    postAudioProgressThrottled,
  },
  store: {
    useAudioStore,
    useCurrentAudio,
    useCurrentChapter,
    useLocalPrefsStore,
  },
  utils: {
    findNextChapter,
    findPreviousChapter,
    findNextAudio,
    findPreviousAudio,
    getAudioFromListCache,
    sessionConversionHistoryAtom,
    DEFAULT_TITLE,
    processingItemStatusString,
    iterateChaptersListUntil,
  },
};
