/* eslint-disable react-refresh/only-export-components -- I don't need fast refresh */
import { waitForElement } from '@/lib/utils';
import { analyticsService } from '@/services/analytics-service';
import type { AudioSummary, ChapterDetail } from '@/services/audio-service';
import { audioService } from '@/services/audio-service';
import { clamp, logger, useContextAndErrorIfNull } from '@listening/shared';
import { useQueryClient, type QueryClient } from '@tanstack/react-query';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import React, { useCallback, useEffect, useState } from 'react';
import type { AudioLoadOptions } from 'react-use-audio-player';
import { useGlobalAudioPlayer } from 'react-use-audio-player';

function useLoadAudioItemInternal() {
  const { load, looping, seek, togglePlayPause, playing } =
    useGlobalAudioPlayer();
  const { endSession } = useTrackPlayTime();
  const playbackRate = audioService.store.useLocalPrefsStore(
    (state) => state.playbackRate,
  );
  const volume = audioService.store.useLocalPrefsStore((state) =>
    state.getVolume(),
  );
  const setAudioItem = audioService.store.useAudioStore(
    (state) => state.setAudioItem,
  );
  const currentAudioItem = audioService.store.useCurrentAudio().query.data;
  const currentChapter = audioService.store.useCurrentChapter().query.data;
  const queryClient = useQueryClient();

  return {
    loadAudioItem: async (
      audioItem: AudioSummary,
      chapter?: ChapterDetail,
      timestamp_ms?: number,
      audioLoadOptions: AudioLoadOptions = {},
    ) => {
      endSession();

      const selectedChapter = await (async () => {
        if (chapter) return chapter;
        if (audioItem.progress) {
          return await queryClient.fetchQuery(
            audioService.queryOpts.fetchChapterQueryOpts({
              queryClient,
              chapter: {
                audioId: audioItem.id,
                chapterId: audioItem.progress.chapter_id,
              },
            }),
          );
        }
        const chapterList = await queryClient.ensureInfiniteQueryData(
          audioService.queryOpts.listChapterQueryOpts(audioItem.id),
        );
        const firstChapter = chapterList.pages[0]?.chapters[0];
        if (!firstChapter) throw new Error('No chapters found');
        return firstChapter;
      })();

      if (
        selectedChapter.id === currentChapter?.id &&
        audioItem.id === currentAudioItem?.id
      ) {
        if (!playing) togglePlayPause();
        return selectedChapter;
      }

      if (!selectedChapter.audio_url)
        throw new Error(
          `No audio url found for chapter: ${selectedChapter.id}`,
        );

      try {
        load(selectedChapter.audio_url, {
          autoplay: true,
          html5: true,
          ...audioLoadOptions,
          initialRate: clamp(playbackRate, 0.01, 16),
          initialVolume: clamp(volume, 0, 1),
          onload: () => {
            const selectedPos =
              timestamp_ms ??
              (selectedChapter.id == audioItem.progress?.chapter_id
                ? audioItem.progress.timestamp_ms
                : 0);

            if (selectedPos) {
              audioService.player.postAudioProgressThrottled({
                chapter_id: selectedChapter.id,
                timestamp_ms: selectedPos,
              });
              logger.log('Seeking to', selectedPos / 1000);
              // without the setTimeout, the seek doesn't work
              setTimeout(() => {
                seek(selectedPos / 1000);
                void waitForElement(
                  '[data-jump-to-current-btn="true"]',
                  6 * 1000,
                ).then((el) => {
                  if (el) {
                    logger.log('Clicking jump to current button');
                    (el as HTMLElement).click();
                  } else {
                    logger.warn('Jump to current button not found');
                  }
                });
              }, 250);
            }
          },
          onend: () => {
            logger.log('Audio ended');
            onend(load, looping, queryClient);
          },
        });
        setAudioItem(audioItem.id, selectedChapter.id);

        return selectedChapter;
      } catch (error) {
        logger.error('Error while loading audio', error);
        setAudioItem(undefined, undefined);
        throw error;
      }
    },
  };
}

function onend(
  load: (src: string, options?: AudioLoadOptions) => void,
  looping: boolean,
  queryClient: QueryClient,
) {
  if (looping) return;

  const audioItemId = audioService.store.useAudioStore.getState().audioItemId;
  if (audioItemId === undefined) {
    logger.log('No audio item, not playing next chapter');
    return;
  }

  logger.log(`Finished playing ${audioItemId}`);
  const chapterId = audioService.store.useAudioStore.getState().chapterId;
  if (chapterId === undefined) {
    logger.log('No chapter selected, not playing next chapter');
    return;
  }

  void audioService.utils
    .findNextChapter({
      queryClient,
      audioId: audioItemId,
      chapterId,
    })
    .then((nextChapter) => {
      if (!nextChapter) {
        logger.log('Last chapter, not playing next chapter');
        return;
      }
      if (!nextChapter.audio_url) {
        logger.error('No audio url found for chapter', nextChapter.id);
        return;
      }
      audioService.store.useAudioStore.getState().setChapter(nextChapter.id);
      load(nextChapter.audio_url, {
        html5: true,
        autoplay: true,
        initialRate:
          audioService.store.useLocalPrefsStore.getState().playbackRate,
        initialVolume: audioService.store.useLocalPrefsStore
          .getState()
          .getVolume(),

        onend: () => {
          onend(load, looping, queryClient);
        },
      });
    });
}

type LoadFunction = ReturnType<
  typeof useLoadAudioItemInternal
>['loadAudioItem'];
const AudioLoadContext = React.createContext<{
  loadAudio: LoadFunction;
  // We know that all the access to this will be within the provider
} | null>(null);

export const AudioLoadProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { loadAudioItem } = useLoadAudioItemInternal();
  return (
    <AudioLoadContext.Provider value={{ loadAudio: loadAudioItem }}>
      {children}
    </AudioLoadContext.Provider>
  );
};

export function useLoadAudioAndNavigate() {
  const loadAudio = useLoadAudio();
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  return (audioItem: AudioSummary, chapter?: ChapterDetail) => {
    void loadAudio(audioItem, chapter).then((selectedChapter) => {
      const params = matchRoute({ to: '/readalong/$audioId/$chapterId' });
      if (
        params &&
        params.audioId === audioItem.id &&
        params.chapterId === chapter?.id
      )
        return;

      void navigate({
        to: '/readalong/$audioId/$chapterId',
        params: {
          audioId: audioItem.id,
          chapterId: selectedChapter.id,
        },
      });
    });
  };
}

export function useLoadAudio() {
  return useContextAndErrorIfNull(AudioLoadContext).loadAudio;
}

function useTrackPlayTime() {
  const { playing, rate, duration, getPosition } = useGlobalAudioPlayer();
  const audioItem = audioService.store.useCurrentAudio().query.data;
  const chapter = audioService.store.useCurrentChapter().query.data;
  const queryClient = useQueryClient();

  const [sessionDetails, setSessionDetails] = useState<null | {
    audioItem: { id: string };
    chapter: { id: string };

    sessionId: string;
    startTime: number;
    rate: number;
  }>(null);

  const startSession = useCallback(() => {
    if (!audioItem || !chapter) {
      logger.warn('No audio item found, cannot start play time session');
      return;
    }
    if (!playing) {
      logger.error('Audio is not playing, cannot start play time session');
      return;
    }
    const sessionDetails = {
      audioItem: { id: audioItem.id },
      chapter: { id: chapter.id },
      sessionId: crypto.randomUUID(),
      startTime: Date.now(),
      rate,
    };

    setSessionDetails(sessionDetails);
    analyticsService.trackLogStartedPlayingAudio({
      audio_item_id: audioItem.id,
      chapter_id: chapter.id,
      chapter_title: chapter.title,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: rate,
      audio_duration_sec: duration,
      starting_position_sec: getPosition(),
    });
  }, [audioItem, chapter, duration, getPosition, playing, rate]);

  const endSession = useCallback(() => {
    if (!sessionDetails) {
      logger.error('No session details found, cannot end play time session');
      return;
    }
    const endTime = Date.now();
    const duration = (endTime - sessionDetails.startTime) * sessionDetails.rate;
    const rateModifiedDuration = rate * duration;
    if (sessionDetails.chapter.id)
      audioService.player.postAudioProgressThrottled({
        chapter_id: sessionDetails.chapter.id,
        timestamp_ms: getPosition() * 1000,
      });
    analyticsService.trackLogStoppedPlayingAudio({
      audio_item_id: sessionDetails.audioItem.id,
      chapter_id: sessionDetails.chapter.id,
      listen_session_id: sessionDetails.sessionId,
      playback_rate: sessionDetails.rate,
      rate_modified_duration_sec: rateModifiedDuration / 1000,
      real_world_duration_sec: duration / 1000,
    });

    setSessionDetails(null);
  }, [getPosition, rate, sessionDetails]);

  useEffect(() => {
    if (!playing || !sessionDetails) return;
    const timer = setInterval(() => {
      audioService.player.postAudioProgressThrottled({
        chapter_id: sessionDetails.chapter.id,
        timestamp_ms: getPosition() * 1000,
      });
    }, 5 * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [getPosition, playing, queryClient, sessionDetails]);

  const restartSession = useCallback(() => {
    endSession();
    startSession();
  }, [endSession, startSession]);

  useEffect(() => {
    if (playing && !sessionDetails) {
      startSession();
    } else if (!playing && sessionDetails) {
      endSession();
    } else if (sessionDetails && rate !== sessionDetails.rate) {
      restartSession();
    }
  }, [endSession, playing, rate, restartSession, sessionDetails, startSession]);

  return {
    endSession,
    restartSession,
    startSession,
  };
}
