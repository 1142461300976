import * as React from 'react';

import { cn } from '@listening/shared';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';

const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-4 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

export type InputProps = {
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  wrapperClassName?: string;
} & React.InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof InputVariants>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      icon,
      rightIcon,
      iconPosition,
      wrapperClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <>
        {icon || rightIcon ? (
          <div
            className={cn(
              'relative inline-flex h-[48px] items-center',
              wrapperClassName,
            )}
          >
            {icon && iconPosition !== 'right' ? (
              <span className={cn(InputVariants({ iconPosition }))}>
                {icon}
              </span>
            ) : null}
            <input
              type={type}
              className={cn(
                'flex h-full w-full rounded-md border border-input bg-transparent py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
                className,
                icon && iconPosition !== 'right' ? 'pl-11' : 'pl-4',
                rightIcon ? 'pr-10' : 'pr-4',
              )}
              ref={ref}
              {...props}
            />
            {rightIcon ? (
              <span
                className={cn(
                  'absolute right-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
                )}
              >
                {rightIcon}
              </span>
            ) : null}
            {icon && iconPosition === 'right' && !rightIcon ? (
              <span className={cn(InputVariants({ iconPosition }))}>
                {icon}
              </span>
            ) : null}
          </div>
        ) : (
          <input
            type={type}
            className={cn(
              'flex h-10 w-full rounded-md border border-input bg-transparent px-4 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground/50 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50',
              className,
            )}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);
Input.displayName = 'Input';

export { Input };
