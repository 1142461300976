/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AddcontentImport } from './routes/add_content'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as IndexImport } from './routes/index'
import { Route as AuthenticatedPremiumImport } from './routes/_authenticated/premium'
import { Route as AuthenticatedDashboardImport } from './routes/_authenticated/_dashboard'
import { Route as authResgisterImport } from './routes/(auth)/resgister'
import { Route as authLoginImport } from './routes/(auth)/login'
import { Route as authContinueImport } from './routes/(auth)/continue'
import { Route as AuthenticatedDashboardProfileImport } from './routes/_authenticated/_dashboard/profile'
import { Route as AuthenticatedDashboardPlanImport } from './routes/_authenticated/_dashboard/plan'
import { Route as AuthenticatedDashboardHomeImport } from './routes/_authenticated/_dashboard/home'
import { Route as AuthenticatedDashboardSettingsIndexImport } from './routes/_authenticated/_dashboard/settings/index'
import { Route as AuthenticatedDashboardFilesIndexImport } from './routes/_authenticated/_dashboard/files/index'
import { Route as AuthenticatedDashboardFilesFolderIdImport } from './routes/_authenticated/_dashboard/files/$folderId'

// Create Virtual Routes

const authRegisterLazyImport = createFileRoute('/(auth)/register')()
const AuthenticatedDashboardNotesLazyImport = createFileRoute(
  '/_authenticated/_dashboard/notes',
)()
const AuthenticatedDashboardSettingsVoiceLazyImport = createFileRoute(
  '/_authenticated/_dashboard/settings/voice',
)()
const AuthenticatedDashboardSettingsLinkOauthLazyImport = createFileRoute(
  '/_authenticated/_dashboard/settings/link-oauth',
)()
const AuthenticatedDashboardSettingsAppearanceLazyImport = createFileRoute(
  '/_authenticated/_dashboard/settings/appearance',
)()
const AuthenticatedDashboardReadalongAudioIdChapterIdLazyImport =
  createFileRoute('/_authenticated/_dashboard/readalong/$audioId/$chapterId')()

// Create/Update Routes

const AddcontentRoute = AddcontentImport.update({
  id: '/add_content',
  path: '/add_content',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/index.lazy').then((d) => d.Route))

const authRegisterLazyRoute = authRegisterLazyImport
  .update({
    id: '/(auth)/register',
    path: '/register',
    getParentRoute: () => rootRoute,
  } as any)
  .lazy(() => import('./routes/(auth)/register.lazy').then((d) => d.Route))

const AuthenticatedPremiumRoute = AuthenticatedPremiumImport.update({
  id: '/premium',
  path: '/premium',
  getParentRoute: () => AuthenticatedRoute,
} as any).lazy(() =>
  import('./routes/_authenticated/premium.lazy').then((d) => d.Route),
)

const AuthenticatedDashboardRoute = AuthenticatedDashboardImport.update({
  id: '/_dashboard',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const authResgisterRoute = authResgisterImport.update({
  id: '/(auth)/resgister',
  path: '/resgister',
  getParentRoute: () => rootRoute,
} as any)

const authLoginRoute = authLoginImport
  .update({
    id: '/(auth)/login',
    path: '/login',
    getParentRoute: () => rootRoute,
  } as any)
  .lazy(() => import('./routes/(auth)/login.lazy').then((d) => d.Route))

const authContinueRoute = authContinueImport
  .update({
    id: '/(auth)/continue',
    path: '/continue',
    getParentRoute: () => rootRoute,
  } as any)
  .lazy(() => import('./routes/(auth)/continue.lazy').then((d) => d.Route))

const AuthenticatedDashboardNotesLazyRoute =
  AuthenticatedDashboardNotesLazyImport.update({
    id: '/notes',
    path: '/notes',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/notes.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardProfileRoute =
  AuthenticatedDashboardProfileImport.update({
    id: '/profile',
    path: '/profile',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/profile.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardPlanRoute = AuthenticatedDashboardPlanImport.update(
  {
    id: '/plan',
    path: '/plan',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any,
).lazy(() =>
  import('./routes/_authenticated/_dashboard/plan.lazy').then((d) => d.Route),
)

const AuthenticatedDashboardHomeRoute = AuthenticatedDashboardHomeImport.update(
  {
    id: '/home',
    path: '/home',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any,
).lazy(() =>
  import('./routes/_authenticated/_dashboard/home.lazy').then((d) => d.Route),
)

const AuthenticatedDashboardSettingsIndexRoute =
  AuthenticatedDashboardSettingsIndexImport.update({
    id: '/settings/',
    path: '/settings/',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/settings/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardFilesIndexRoute =
  AuthenticatedDashboardFilesIndexImport.update({
    id: '/files/',
    path: '/files/',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/files/index.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardSettingsVoiceLazyRoute =
  AuthenticatedDashboardSettingsVoiceLazyImport.update({
    id: '/settings/voice',
    path: '/settings/voice',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/settings/voice.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardSettingsLinkOauthLazyRoute =
  AuthenticatedDashboardSettingsLinkOauthLazyImport.update({
    id: '/settings/link-oauth',
    path: '/settings/link-oauth',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/settings/link-oauth.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardSettingsAppearanceLazyRoute =
  AuthenticatedDashboardSettingsAppearanceLazyImport.update({
    id: '/settings/appearance',
    path: '/settings/appearance',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/settings/appearance.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardFilesFolderIdRoute =
  AuthenticatedDashboardFilesFolderIdImport.update({
    id: '/files/$folderId',
    path: '/files/$folderId',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import('./routes/_authenticated/_dashboard/files/$folderId.lazy').then(
      (d) => d.Route,
    ),
  )

const AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute =
  AuthenticatedDashboardReadalongAudioIdChapterIdLazyImport.update({
    id: '/readalong/$audioId/$chapterId',
    path: '/readalong/$audioId/$chapterId',
    getParentRoute: () => AuthenticatedDashboardRoute,
  } as any).lazy(() =>
    import(
      './routes/_authenticated/_dashboard/readalong.$audioId.$chapterId.lazy'
    ).then((d) => d.Route),
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/add_content': {
      id: '/add_content'
      path: '/add_content'
      fullPath: '/add_content'
      preLoaderRoute: typeof AddcontentImport
      parentRoute: typeof rootRoute
    }
    '/(auth)/continue': {
      id: '/(auth)/continue'
      path: '/continue'
      fullPath: '/continue'
      preLoaderRoute: typeof authContinueImport
      parentRoute: typeof rootRoute
    }
    '/(auth)/login': {
      id: '/(auth)/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof authLoginImport
      parentRoute: typeof rootRoute
    }
    '/(auth)/resgister': {
      id: '/(auth)/resgister'
      path: '/resgister'
      fullPath: '/resgister'
      preLoaderRoute: typeof authResgisterImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_dashboard': {
      id: '/_authenticated/_dashboard'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedDashboardImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/premium': {
      id: '/_authenticated/premium'
      path: '/premium'
      fullPath: '/premium'
      preLoaderRoute: typeof AuthenticatedPremiumImport
      parentRoute: typeof AuthenticatedImport
    }
    '/(auth)/register': {
      id: '/(auth)/register'
      path: '/register'
      fullPath: '/register'
      preLoaderRoute: typeof authRegisterLazyImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_dashboard/home': {
      id: '/_authenticated/_dashboard/home'
      path: '/home'
      fullPath: '/home'
      preLoaderRoute: typeof AuthenticatedDashboardHomeImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/plan': {
      id: '/_authenticated/_dashboard/plan'
      path: '/plan'
      fullPath: '/plan'
      preLoaderRoute: typeof AuthenticatedDashboardPlanImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/profile': {
      id: '/_authenticated/_dashboard/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthenticatedDashboardProfileImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/notes': {
      id: '/_authenticated/_dashboard/notes'
      path: '/notes'
      fullPath: '/notes'
      preLoaderRoute: typeof AuthenticatedDashboardNotesLazyImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/files/$folderId': {
      id: '/_authenticated/_dashboard/files/$folderId'
      path: '/files/$folderId'
      fullPath: '/files/$folderId'
      preLoaderRoute: typeof AuthenticatedDashboardFilesFolderIdImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/settings/appearance': {
      id: '/_authenticated/_dashboard/settings/appearance'
      path: '/settings/appearance'
      fullPath: '/settings/appearance'
      preLoaderRoute: typeof AuthenticatedDashboardSettingsAppearanceLazyImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/settings/link-oauth': {
      id: '/_authenticated/_dashboard/settings/link-oauth'
      path: '/settings/link-oauth'
      fullPath: '/settings/link-oauth'
      preLoaderRoute: typeof AuthenticatedDashboardSettingsLinkOauthLazyImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/settings/voice': {
      id: '/_authenticated/_dashboard/settings/voice'
      path: '/settings/voice'
      fullPath: '/settings/voice'
      preLoaderRoute: typeof AuthenticatedDashboardSettingsVoiceLazyImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/files/': {
      id: '/_authenticated/_dashboard/files/'
      path: '/files'
      fullPath: '/files'
      preLoaderRoute: typeof AuthenticatedDashboardFilesIndexImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/settings/': {
      id: '/_authenticated/_dashboard/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthenticatedDashboardSettingsIndexImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
    '/_authenticated/_dashboard/readalong/$audioId/$chapterId': {
      id: '/_authenticated/_dashboard/readalong/$audioId/$chapterId'
      path: '/readalong/$audioId/$chapterId'
      fullPath: '/readalong/$audioId/$chapterId'
      preLoaderRoute: typeof AuthenticatedDashboardReadalongAudioIdChapterIdLazyImport
      parentRoute: typeof AuthenticatedDashboardImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedDashboardRouteChildren {
  AuthenticatedDashboardHomeRoute: typeof AuthenticatedDashboardHomeRoute
  AuthenticatedDashboardPlanRoute: typeof AuthenticatedDashboardPlanRoute
  AuthenticatedDashboardProfileRoute: typeof AuthenticatedDashboardProfileRoute
  AuthenticatedDashboardNotesLazyRoute: typeof AuthenticatedDashboardNotesLazyRoute
  AuthenticatedDashboardFilesFolderIdRoute: typeof AuthenticatedDashboardFilesFolderIdRoute
  AuthenticatedDashboardSettingsAppearanceLazyRoute: typeof AuthenticatedDashboardSettingsAppearanceLazyRoute
  AuthenticatedDashboardSettingsLinkOauthLazyRoute: typeof AuthenticatedDashboardSettingsLinkOauthLazyRoute
  AuthenticatedDashboardSettingsVoiceLazyRoute: typeof AuthenticatedDashboardSettingsVoiceLazyRoute
  AuthenticatedDashboardFilesIndexRoute: typeof AuthenticatedDashboardFilesIndexRoute
  AuthenticatedDashboardSettingsIndexRoute: typeof AuthenticatedDashboardSettingsIndexRoute
  AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute: typeof AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute
}

const AuthenticatedDashboardRouteChildren: AuthenticatedDashboardRouteChildren =
  {
    AuthenticatedDashboardHomeRoute: AuthenticatedDashboardHomeRoute,
    AuthenticatedDashboardPlanRoute: AuthenticatedDashboardPlanRoute,
    AuthenticatedDashboardProfileRoute: AuthenticatedDashboardProfileRoute,
    AuthenticatedDashboardNotesLazyRoute: AuthenticatedDashboardNotesLazyRoute,
    AuthenticatedDashboardFilesFolderIdRoute:
      AuthenticatedDashboardFilesFolderIdRoute,
    AuthenticatedDashboardSettingsAppearanceLazyRoute:
      AuthenticatedDashboardSettingsAppearanceLazyRoute,
    AuthenticatedDashboardSettingsLinkOauthLazyRoute:
      AuthenticatedDashboardSettingsLinkOauthLazyRoute,
    AuthenticatedDashboardSettingsVoiceLazyRoute:
      AuthenticatedDashboardSettingsVoiceLazyRoute,
    AuthenticatedDashboardFilesIndexRoute:
      AuthenticatedDashboardFilesIndexRoute,
    AuthenticatedDashboardSettingsIndexRoute:
      AuthenticatedDashboardSettingsIndexRoute,
    AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute:
      AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute,
  }

const AuthenticatedDashboardRouteWithChildren =
  AuthenticatedDashboardRoute._addFileChildren(
    AuthenticatedDashboardRouteChildren,
  )

interface AuthenticatedRouteChildren {
  AuthenticatedDashboardRoute: typeof AuthenticatedDashboardRouteWithChildren
  AuthenticatedPremiumRoute: typeof AuthenticatedPremiumRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedDashboardRoute: AuthenticatedDashboardRouteWithChildren,
  AuthenticatedPremiumRoute: AuthenticatedPremiumRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthenticatedDashboardRouteWithChildren
  '/add_content': typeof AddcontentRoute
  '/continue': typeof authContinueRoute
  '/login': typeof authLoginRoute
  '/resgister': typeof authResgisterRoute
  '/premium': typeof AuthenticatedPremiumRoute
  '/register': typeof authRegisterLazyRoute
  '/home': typeof AuthenticatedDashboardHomeRoute
  '/plan': typeof AuthenticatedDashboardPlanRoute
  '/profile': typeof AuthenticatedDashboardProfileRoute
  '/notes': typeof AuthenticatedDashboardNotesLazyRoute
  '/files/$folderId': typeof AuthenticatedDashboardFilesFolderIdRoute
  '/settings/appearance': typeof AuthenticatedDashboardSettingsAppearanceLazyRoute
  '/settings/link-oauth': typeof AuthenticatedDashboardSettingsLinkOauthLazyRoute
  '/settings/voice': typeof AuthenticatedDashboardSettingsVoiceLazyRoute
  '/files': typeof AuthenticatedDashboardFilesIndexRoute
  '/settings': typeof AuthenticatedDashboardSettingsIndexRoute
  '/readalong/$audioId/$chapterId': typeof AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthenticatedDashboardRouteWithChildren
  '/add_content': typeof AddcontentRoute
  '/continue': typeof authContinueRoute
  '/login': typeof authLoginRoute
  '/resgister': typeof authResgisterRoute
  '/premium': typeof AuthenticatedPremiumRoute
  '/register': typeof authRegisterLazyRoute
  '/home': typeof AuthenticatedDashboardHomeRoute
  '/plan': typeof AuthenticatedDashboardPlanRoute
  '/profile': typeof AuthenticatedDashboardProfileRoute
  '/notes': typeof AuthenticatedDashboardNotesLazyRoute
  '/files/$folderId': typeof AuthenticatedDashboardFilesFolderIdRoute
  '/settings/appearance': typeof AuthenticatedDashboardSettingsAppearanceLazyRoute
  '/settings/link-oauth': typeof AuthenticatedDashboardSettingsLinkOauthLazyRoute
  '/settings/voice': typeof AuthenticatedDashboardSettingsVoiceLazyRoute
  '/files': typeof AuthenticatedDashboardFilesIndexRoute
  '/settings': typeof AuthenticatedDashboardSettingsIndexRoute
  '/readalong/$audioId/$chapterId': typeof AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/add_content': typeof AddcontentRoute
  '/(auth)/continue': typeof authContinueRoute
  '/(auth)/login': typeof authLoginRoute
  '/(auth)/resgister': typeof authResgisterRoute
  '/_authenticated/_dashboard': typeof AuthenticatedDashboardRouteWithChildren
  '/_authenticated/premium': typeof AuthenticatedPremiumRoute
  '/(auth)/register': typeof authRegisterLazyRoute
  '/_authenticated/_dashboard/home': typeof AuthenticatedDashboardHomeRoute
  '/_authenticated/_dashboard/plan': typeof AuthenticatedDashboardPlanRoute
  '/_authenticated/_dashboard/profile': typeof AuthenticatedDashboardProfileRoute
  '/_authenticated/_dashboard/notes': typeof AuthenticatedDashboardNotesLazyRoute
  '/_authenticated/_dashboard/files/$folderId': typeof AuthenticatedDashboardFilesFolderIdRoute
  '/_authenticated/_dashboard/settings/appearance': typeof AuthenticatedDashboardSettingsAppearanceLazyRoute
  '/_authenticated/_dashboard/settings/link-oauth': typeof AuthenticatedDashboardSettingsLinkOauthLazyRoute
  '/_authenticated/_dashboard/settings/voice': typeof AuthenticatedDashboardSettingsVoiceLazyRoute
  '/_authenticated/_dashboard/files/': typeof AuthenticatedDashboardFilesIndexRoute
  '/_authenticated/_dashboard/settings/': typeof AuthenticatedDashboardSettingsIndexRoute
  '/_authenticated/_dashboard/readalong/$audioId/$chapterId': typeof AuthenticatedDashboardReadalongAudioIdChapterIdLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/add_content'
    | '/continue'
    | '/login'
    | '/resgister'
    | '/premium'
    | '/register'
    | '/home'
    | '/plan'
    | '/profile'
    | '/notes'
    | '/files/$folderId'
    | '/settings/appearance'
    | '/settings/link-oauth'
    | '/settings/voice'
    | '/files'
    | '/settings'
    | '/readalong/$audioId/$chapterId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/add_content'
    | '/continue'
    | '/login'
    | '/resgister'
    | '/premium'
    | '/register'
    | '/home'
    | '/plan'
    | '/profile'
    | '/notes'
    | '/files/$folderId'
    | '/settings/appearance'
    | '/settings/link-oauth'
    | '/settings/voice'
    | '/files'
    | '/settings'
    | '/readalong/$audioId/$chapterId'
  id:
    | '__root__'
    | '/'
    | '/_authenticated'
    | '/add_content'
    | '/(auth)/continue'
    | '/(auth)/login'
    | '/(auth)/resgister'
    | '/_authenticated/_dashboard'
    | '/_authenticated/premium'
    | '/(auth)/register'
    | '/_authenticated/_dashboard/home'
    | '/_authenticated/_dashboard/plan'
    | '/_authenticated/_dashboard/profile'
    | '/_authenticated/_dashboard/notes'
    | '/_authenticated/_dashboard/files/$folderId'
    | '/_authenticated/_dashboard/settings/appearance'
    | '/_authenticated/_dashboard/settings/link-oauth'
    | '/_authenticated/_dashboard/settings/voice'
    | '/_authenticated/_dashboard/files/'
    | '/_authenticated/_dashboard/settings/'
    | '/_authenticated/_dashboard/readalong/$audioId/$chapterId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
  AddcontentRoute: typeof AddcontentRoute
  authContinueRoute: typeof authContinueRoute
  authLoginRoute: typeof authLoginRoute
  authResgisterRoute: typeof authResgisterRoute
  authRegisterLazyRoute: typeof authRegisterLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  AddcontentRoute: AddcontentRoute,
  authContinueRoute: authContinueRoute,
  authLoginRoute: authLoginRoute,
  authResgisterRoute: authResgisterRoute,
  authRegisterLazyRoute: authRegisterLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_authenticated",
        "/add_content",
        "/(auth)/continue",
        "/(auth)/login",
        "/(auth)/resgister",
        "/(auth)/register"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_dashboard",
        "/_authenticated/premium"
      ]
    },
    "/add_content": {
      "filePath": "add_content.tsx"
    },
    "/(auth)/continue": {
      "filePath": "(auth)/continue.tsx"
    },
    "/(auth)/login": {
      "filePath": "(auth)/login.tsx"
    },
    "/(auth)/resgister": {
      "filePath": "(auth)/resgister.tsx"
    },
    "/_authenticated/_dashboard": {
      "filePath": "_authenticated/_dashboard.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_dashboard/home",
        "/_authenticated/_dashboard/plan",
        "/_authenticated/_dashboard/profile",
        "/_authenticated/_dashboard/notes",
        "/_authenticated/_dashboard/files/$folderId",
        "/_authenticated/_dashboard/settings/appearance",
        "/_authenticated/_dashboard/settings/link-oauth",
        "/_authenticated/_dashboard/settings/voice",
        "/_authenticated/_dashboard/files/",
        "/_authenticated/_dashboard/settings/",
        "/_authenticated/_dashboard/readalong/$audioId/$chapterId"
      ]
    },
    "/_authenticated/premium": {
      "filePath": "_authenticated/premium.tsx",
      "parent": "/_authenticated"
    },
    "/(auth)/register": {
      "filePath": "(auth)/register.lazy.tsx"
    },
    "/_authenticated/_dashboard/home": {
      "filePath": "_authenticated/_dashboard/home.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/plan": {
      "filePath": "_authenticated/_dashboard/plan.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/profile": {
      "filePath": "_authenticated/_dashboard/profile.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/notes": {
      "filePath": "_authenticated/_dashboard/notes.lazy.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/files/$folderId": {
      "filePath": "_authenticated/_dashboard/files/$folderId.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/settings/appearance": {
      "filePath": "_authenticated/_dashboard/settings/appearance.lazy.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/settings/link-oauth": {
      "filePath": "_authenticated/_dashboard/settings/link-oauth.lazy.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/settings/voice": {
      "filePath": "_authenticated/_dashboard/settings/voice.lazy.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/files/": {
      "filePath": "_authenticated/_dashboard/files/index.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/settings/": {
      "filePath": "_authenticated/_dashboard/settings/index.tsx",
      "parent": "/_authenticated/_dashboard"
    },
    "/_authenticated/_dashboard/readalong/$audioId/$chapterId": {
      "filePath": "_authenticated/_dashboard/readalong.$audioId.$chapterId.lazy.tsx",
      "parent": "/_authenticated/_dashboard"
    }
  }
}
ROUTE_MANIFEST_END */
