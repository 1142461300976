import { env } from '@/lib/env';
import { envString } from '@/lib/utils';
import * as amplitude from '@amplitude/analytics-browser';
import { Experiment } from '@amplitude/experiment-js-client';
import { AnalyticsBrowser } from '@customerio/cdp-analytics-browser';
import { getAnalyticsService } from '@listening/shared';

export const analyticsService = getAnalyticsService({
  amplitude: {
    amplitude,
    amplitudeExperiment: Experiment,
    apiKey: env.VITE_AMPLITUDE_API_KEY,
  },
  dev: env.DEV,
  envString,
  mode: env.MODE,
  ssr: env.SSR,
  customerIo: {
    siteId: env.VITE_CUSTOMERIO_SITE_ID,
    writeKey: env.VITE_CUSTOMERIO_WRITE_KEY,
    sdk: AnalyticsBrowser as unknown as AnalyticsBrowser,
  },
});
