import { ModalCmdKProvider } from '@/components/dialogs/modal-cmd-k/modal-cmd-k';
import { PremiumDialogsProvider } from '@/components/misc/providers/premium-dialog-provider';
import { UploadDialogsProvider } from '@/components/misc/providers/upload-dialog-providers';
import { NavBar } from '@/components/nav-bar';
import { PlayBar } from '@/components/play-bar';
import { analyticsService } from '@/services/analytics-service';
import { useIsInvalidSubscription } from '@/services/subscription-service';
import { isUserLoggedIn } from '@/services/user-service';
import { Navigate, Outlet, createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated/_dashboard')({
  component: Dashboard,
});

function Dashboard() {
  const isSubscriptionInvalid = useIsInvalidSubscription();
  const freePlanAvailable = analyticsService.useFreePlanAvailable();
  if (!isUserLoggedIn()) return <Navigate to="/login" />;

  if (isSubscriptionInvalid && freePlanAvailable == 'off') {
    return <Navigate to="/premium" />;
  }
  return (
    <PremiumDialogsProvider>
      <UploadDialogsProvider>
        <div className="flex size-full flex-col subpixel-antialiased">
          <div className="flex grow overflow-hidden">
            <NavBar />
            <ModalCmdKProvider>
              <main className="h-full grow overflow-hidden">
                <Outlet />
              </main>
            </ModalCmdKProvider>
          </div>
          <PlayBar />
        </div>
      </UploadDialogsProvider>
    </PremiumDialogsProvider>
  );
}
