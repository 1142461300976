import LockIcon from '@/assets/icons/lock.svg?react';

import CircleCloseIcon from '@/assets/icons/close-circled.svg?react';
import CircleCheckIcon from '@/assets/icons/gold-circle-check.svg?react';
import { DialogContent, DialogDescription, DialogTitle } from '../ui/dialog';

import { useDefaultPaymentPlans } from '@/services/pricing-service';
import { cn, trialDays } from '@listening/shared';
import { Link } from '@tanstack/react-router';
import type React from 'react';
import { CustomerTestimonial } from '../marketing/customer-testimonial-carousel';
import type { PremiumGate } from '../misc/providers/premium-dialog-provider';
import { Button } from '../ui/button';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';

const headerGate: Record<PremiumGate, React.ReactNode> = {
  playback_speed: (
    <>
      {'Listen up to 4x with'}
      <br />
      {'Listening Premium'}
    </>
  ),
  voice: (
    <>
      {'Choose from Premium Voices with'}
      <br />
      {'Listening Premium'}
    </>
  ),
  upload_limit: (
    <>
      {'Upload unlimited papers with'}
      <br />
      {'Listening Premium'}
    </>
  ),
  file_type: (
    <>
      {'Upload more file types with'}
      <br />
      {'Listening Premium'}
    </>
  ),
};

export function PremiumDialog({ gate }: { gate: PremiumGate }) {
  const defaultPaymentPlans = useDefaultPaymentPlans();
  return (
    <DialogContent className="flex max-h-[90%] max-w-[820px] flex-col rounded-2xl p-0">
      <ScrollArea>
        <div className="flex flex-col items-center gap-10 p-4">
          <div className="flex flex-col items-center gap-2">
            <PremiumDialog.Icon />
            <DialogTitle className="text-center text-[32px] font-bold leading-10 text-[#0F0140] dark:text-white">
              {headerGate[gate]}
            </DialogTitle>
            <DialogDescription className="text-sm font-normal text-[#344054] dark:text-[#B8C4D6]">
              and get all these benefits
            </DialogDescription>
          </div>
          <div className="flex gap-6">
            <div className="max-w-[400px]">
              <CustomerTestimonial />
            </div>
            <div className="flex flex-col gap-4">
              <PremiumFeatureRow
                title="Speeds up to 4x"
                subheading="Free is limited to 1.25"
              />
              <PremiumFeatureRow
                title="Enjoy unlimited listening"
                subheading="Free is limited to 3 uploads a week"
              />
              <PremiumFeatureRow
                title="Choose from premium voices"
                subheading="Free is limited to 2 options"
              />
              <PremiumFeatureRow
                title="Listen to emails, MOBI, EPUB"
                subheading="Free is only PDF and websites"
              />
              {/* <PremiumFeatureRow title="Download and listen offline" last /> */}
            </div>
          </div>
          <div className="flex w-full max-w-[560px] flex-col items-center gap-2">
            <Button className="w-full" asChild>
              <Link to="/premium">
                Try Premium for Free for {trialDays} days
              </Link>
            </Button>

            <p className="text-xs text-[#344054] dark:text-[#B8C4D6]">
              After the free trial, it’s ${defaultPaymentPlans.monthly.amount}
              /mo or ${defaultPaymentPlans.yearly.amount}/year. Cancel any time,
              no commitment.
            </p>
          </div>
        </div>
      </ScrollArea>
    </DialogContent>
  );
}

function PremiumFeatureRow({
  title,
  subheading,
  last,
}: {
  title: string;
  subheading?: string;
  last?: boolean;
}) {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <CircleCheckIcon />
          <p className="text-xl font-semibold text-[#0F0140] dark:text-white">
            {title}
          </p>
        </div>
        {subheading && (
          <div className="flex items-center gap-2">
            <CircleCloseIcon />
            <p className="text-sm text-[#8590A2] dark:text-[#B8C4D6]">
              {subheading}
            </p>
          </div>
        )}
      </div>
      {!last && <Separator />}
    </>
  );
}

PremiumDialog.Icon = ({ className }: { className?: string }) => (
  <div className="rounded-full bg-[#1570EF0D] p-2 dark:bg-[#FFFFFF0D]">
    <div className="rounded-full bg-[#1570EF0D] p-3 dark:bg-[#FFFFFF26]">
      <LockIcon
        className={cn('size-6 text-[#1570EF] dark:text-white', className)}
      />
    </div>
  </div>
);
