import { isUserLoggedIn } from '@/services/user-service';
import { logger } from '@listening/shared';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_authenticated')({
  component: () => <Outlet />,
  beforeLoad: ({ location }) => {
    const token = isUserLoggedIn();
    if (!token) {
      logger.log('Not authorized, redirecting to login', location);

      const redirectTo = `${location.pathname}${location.searchStr}`;

      return redirect({
        to: '/login',
        search: {
          redirectTo,
        },
      });
    }
  },
});
