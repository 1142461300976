import { ApiException } from '@/lib/exceptions';
import { auth, useFirebaseSignOut } from '@/lib/firebase';
import { analyticsService } from '@/services/analytics-service';
import { audioService } from '@/services/audio-service';
import { useLoginMutation, useRegisterMutation } from '@/services/auth-service';
import { useFeatureFlagStore } from '@/stores/feature-flag-store';
import { zodResolver } from '@hookform/resolvers/zod';
import { logger, useTrackingStore, useUserStore } from '@listening/shared';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { FirebaseError } from 'firebase/app';
import { signInWithPopup, type AuthProvider } from 'firebase/auth';
import { useAtom } from 'jotai';
import { useState } from 'react';
import type { Resolver } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useGlobalAudioPlayer } from 'react-use-audio-player';
import * as z from 'zod';

const authFormSchema = z.object({
  email: z.string().email().min(1).max(80),
  password: z.string().min(1).max(80),
});

type FormValues = z.infer<typeof authFormSchema>;
export type AuthMode = 'login' | 'signup';
export type AuthSuccessResult = {
  mode: AuthMode;
  redirectLocation: string;
};
export function useAuthMutations(opts: {
  onSuccess: (result: AuthSuccessResult) => void;
  initialMode?: AuthMode;
  registerAllowed?: boolean;
}) {
  const searchParams = useSearch({ strict: false });

  const form = useAuthForm();
  const [mode, setMode] = useState<AuthMode>(opts.initialMode ?? 'login');

  const loginMutation = useLoginMutation();
  const registerMutation = useRegisterMutation();
  const firebaseSignOutMutation = useFirebaseSignOut();

  const mutation = useMutation({
    mutationFn: async (
      values: Parameters<typeof onSubmit>[0],
    ): Promise<{
      action: AuthMode;
      creds: 'oauth' | 'email-password';
    }> => {
      const data = await (async () => {
        if ('oauthProvider' in values) {
          const userCred = await signInWithPopup(auth, values.oauthProvider);
          logger.log('Firebase user', userCred.user.uid);
          const token = await userCred.user.getIdToken();
          const email = userCred.user.email;
          if (!email) throw new Error('Email not found');
          return {
            firebase_id_token: token,
            email,
          };
        }
        return values;
      })();

      const trackingStoreState = useTrackingStore.getState();
      const utmParams = {
        utm_source: trackingStoreState.params.utm_source?.value,
        utm_medium: trackingStoreState.params.utm_medium?.value,
        utm_campaign: trackingStoreState.params.utm_campaign?.value,
        utm_term: trackingStoreState.params.utm_term?.value,
        utm_content: trackingStoreState.params.utm_content?.value,
      };

      const defaultRegisterProps = {
        email: data.email,
        utmParams,
      };

      if ('firebase_id_token' in data) {
        // first try to login
        try {
          await loginMutation.mutateAsync(data);
          return { action: 'login', creds: 'oauth' };
        } catch (loginError) {
          const userNotFound =
            ApiException.fromError(loginError)?.response?.status === 404;
          const oauthProvider =
            'oauthProvider' in values ? values.oauthProvider : null;
          const providerLabel =
            oauthProvider?.providerId === 'google.com'
              ? 'Google'
              : oauthProvider?.providerId === 'apple.com'
                ? 'Apple'
                : 'Unknown';
          if (userNotFound) {
            const betterUserNotFoundError = new ApiException(
              `You’ve signed up using email and password. To log in with ${providerLabel}, log in with email first, then add your ${providerLabel} account in the Settings page`,
              'CLIENT_ERROR',
            );

            if (opts.registerAllowed != true) throw betterUserNotFoundError;

            // if login fails, try to register
            try {
              await registerMutation.mutateAsync({
                firebase_id_token: data.firebase_id_token,
                random_password: true,
                ...defaultRegisterProps,
              });
            } catch (registerError) {
              const registerApiError = ApiException.fromError(registerError);
              const error =
                registerApiError?.message.includes('email already exists') ||
                registerApiError?.response?.status == 409
                  ? betterUserNotFoundError
                  : registerError;

              throw error;
            }
            return { action: 'signup', creds: 'oauth' };
          } else {
            throw loginError;
          }
        }
      }

      if (mode === 'signup' && opts.registerAllowed == true) {
        await registerMutation.mutateAsync({
          password: data.password,
          random_password: false,
          ...defaultRegisterProps,
        });
        return { action: 'signup', creds: 'email-password' };
      }

      await loginMutation.mutateAsync(data);
      return { action: 'login', creds: 'email-password' };
    },
  });

  const handleError = (e: unknown, values: Parameters<typeof onSubmit>[0]) => {
    logger.log('Handle Auth Error', e);
    if (e instanceof FirebaseError && e.code === 'auth/popup-closed-by-user')
      return;
    const apiError = ApiException.fromError(e);
    logger.warn(`${mode} request failed`);
    if (!apiError) {
      form.setError('root', {
        type: '400',
        message: ApiException.getErrorMessage(e),
      });
      return;
    }
    if ('oauthProvider' in values && apiError.response?.status === 404) {
      firebaseSignOutMutation.mutate();
      form.setError('root', {
        type: '400',
        message: 'User not found',
      });
      throw e;
    }
    form.setError('root', {
      type: '400',
      message: ApiException.getErrorMessage(e),
    });
  };

  function onSubmit(
    values: z.infer<typeof authFormSchema> | { oauthProvider: AuthProvider },
  ) {
    form.clearErrors('root');
    form.clearErrors('email');
    form.clearErrors('password');
    mutation.mutate(values, {
      onError: (e) => {
        handleError(e, values);
      },
      onSuccess: (authAction) => {
        const defaultRedirect =
          authAction.action == 'signup' ? '/premium' : '/';
        const redirectLocation = searchParams.redirectTo ?? defaultRedirect;

        analyticsService.trackLogV2('auth_success', {
          mode: authAction.action,
          redirect_location: redirectLocation,
        });
        logger.log(
          `${authAction.action} successful, redirectLocation: ${redirectLocation}`,
        );
        opts.onSuccess({ mode: authAction.action, redirectLocation });
      },
    });
  }

  return { onSubmit, mutation, form, mode, setMode };
}

export type AuthForm = ReturnType<typeof useAuthForm>;
const useAuthForm = () =>
  useForm<FormValues>({
    resolver: zodResolver(authFormSchema) as Resolver<FormValues>,
    defaultValues: {
      email: '',
      password: '',
    },
  });

export function useLogoutAndClearCache() {
  const logout = useUserStore((state) => state.logout);
  const audioPlayer = useGlobalAudioPlayer();
  const [, setHistory] = useAtom(
    audioService.utils.sessionConversionHistoryAtom,
  );
  const queryClient = useQueryClient();
  const setAudioItem = audioService.store.useAudioStore(
    (state) => state.setAudioItem,
  );
  const localPrefsStoreReset = audioService.store.useLocalPrefsStore(
    (s) => s.reset,
  );
  const signOutFirebase = useFirebaseSignOut();
  const ffStore = useFeatureFlagStore();
  const navigate = useNavigate();

  return () => {
    audioPlayer.stop();
    logout();
    setHistory([]);
    setAudioItem(undefined, undefined);
    queryClient.clear();
    void queryClient.resetQueries();
    signOutFirebase.mutate();
    localPrefsStoreReset();
    ffStore.reset();
    void navigate({
      to: '/login',
    });
  };
}
