import { ForwardEmailDialog } from '@/components/dialogs/forward-email-dialog';
import { LinkWebDialog } from '@/components/dialogs/link-web-dialog';
import { UploadFileDialog } from '@/components/dialogs/upload-file-dialog';
import { DialogCloseProvider } from '@/components/ui/dialog-dropdown';
import React, { createContext } from 'react';

export type UploadDialogType = 'url' | 'file' | 'email';

const UploadDialogsContext = createContext<{
  currentOpenDialog: UploadDialogType | null;
  setCurrentOpenDialog: (dialog: UploadDialogType | null) => void;
} | null>(null);

export function UploadDialogsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentOpenDialog, setCurrentOpenDialog] =
    React.useState<UploadDialogType | null>(null);

  return (
    <UploadDialogsContext.Provider
      value={{
        currentOpenDialog,
        setCurrentOpenDialog,
      }}
    >
      <DialogCloseProvider
        open={currentOpenDialog != null}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setCurrentOpenDialog(null);
          }
        }}
      >
        {children}
        {currentOpenDialog === 'url' ? (
          <LinkWebDialog />
        ) : currentOpenDialog === 'file' ? (
          <UploadFileDialog />
        ) : currentOpenDialog === 'email' ? (
          <ForwardEmailDialog />
        ) : null}
      </DialogCloseProvider>
    </UploadDialogsContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components -- I know the risks
export function useUploadDialogs() {
  const context = React.useContext(UploadDialogsContext);
  if (!context) {
    throw new Error(
      'useUploadDialogs must be used within a UploadDialogsProvider',
    );
  }
  return context;
}
