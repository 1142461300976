import { env } from '@/lib/env';
import {
  getDefaultPaymentPlans,
  getSpecialPromoCodePaymentPlans,
  getSpecialSecondOfferPaymentPlans,
} from '@listening/shared';
import { analyticsService } from './analytics-service';

export const useDefaultPaymentPlans = () => {
  const plans = getDefaultPaymentPlans(env);
  const onboardingFF = analyticsService.useNewOnboarding25();
  return onboardingFF == 'on' ? plans.afterOnboarding : plans.beforeOnboarding;
};

export const specialPromoCodePaymentPlans =
  getSpecialPromoCodePaymentPlans(env);

export const specialPlanPromoCodes = Object.keys(specialPromoCodePaymentPlans);

export const specialSecondOfferPaymentPlans =
  getSpecialSecondOfferPaymentPlans(env);
