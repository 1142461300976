import { specialPlanPromoCodes } from '@listening/shared';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

const premiumSearchParamsSchema = z.object({
  // We don't want to show an error if seach is malformed
  // https://tanstack.com/router/latest/docs/framework/react/guide/search-params#validating-search-params
  promo: z.enum(specialPlanPromoCodes).optional().catch(undefined),
  '7daytrial': z.boolean().optional().catch(undefined),
  'special-offer': z.boolean().optional().catch(undefined),
});

export const Route = createFileRoute('/_authenticated/premium')({
  validateSearch: premiumSearchParamsSchema,
});
