import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@listening/shared';

// eslint-disable-next-line react-refresh/only-export-components -- I know the risks
export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-destructive text-white hover:bg-destructive/60',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline',
        gradient:
          'relative w-full rounded-full bg-[#102087] text-white hover:bg-[#102087]/80',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'size-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonProps = {
  asChild?: boolean;
  wrapperClassName?: string;
  animated?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      wrapperClassName,
      animated = false,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    // If animated is true, return the animated button with gradient border
    if (animated) {
      return (
        <div className={cn('relative', wrapperClassName)}>
          <div className="relative overflow-hidden rounded-full p-[3px]">
            <div
              className="absolute inset-[-3px] animate-border-shine rounded-full bg-[200%_auto]"
              style={{
                backgroundImage: `linear-gradient(
                  to right,
                  #EC0C92 0%,
                  #1820D9 25%,
                  #1C92FF 50%,
                  #00FFE0 75%,
                  #EC0C92 100%
                )`,
              }}
              aria-hidden="true"
            />
            <Comp
              className={cn(
                buttonVariants({
                  variant: variant ?? 'gradient',
                  size,
                  className: cn('relative', className),
                }),
              )}
              ref={ref}
              {...props}
            />
          </div>
        </div>
      );
    }

    // Regular button without animation
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = 'Button';

export { Button };

// Animated Button Usages

/* <div className="relative w-full">
            <div
              className={cn(
                'relative overflow-hidden rounded-full p-[3px]',
                mutation.isPending && 'opacity-50',
              )}
            >
              <div
                className="absolute inset-[-3px] animate-border-shine rounded-full bg-[200%_auto]"
                style={{
                  background: `linear-gradient(
                    to right,
                    #EC0C92 0%,
                    #1820D9 25%,
                    #1C92FF 50%,
                    #00FFE0 75%,
                    #EC0C92 100%
                  )`,
                }}
                aria-hidden="true"
              />
              <AuthFormSubmitButton
                isPending={mutation.isPending}
                className="relative w-full rounded-full bg-[#102087] px-8 py-5 font-jakarta text-xl font-light text-white hover:bg-[#102087]/80 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:py-7"
              >
                {mode === 'signup' ? 'Create Account' : 'Login'}
              </AuthFormSubmitButton>
            </div>
          </div> */

// export function AuthFormSubmitButton({
//   className,
//   isPending,
//   children,
// }: {
//   isPending: boolean;
//   children?: React.ReactNode;
//   className?: string;
// }) {
//   return (
//     <Button
//       type="submit"
//       disabled={isPending}
//       className={cn(
//         'flex h-[52px] gap-2 rounded-full text-base font-[600] leading-5',
//         className,
//         isPending ? 'cursor-wait' : 'cursor-pointer',
//       )}
//       size="lg"
//     >
//       {isPending && <Spinner />}
//       {children ?? 'Continue with email'}
//     </Button>
//   );
// }

/* <div
className="absolute inset-[-3px] animate-border-shine rounded-full bg-[200%_auto]"
style={{
  background: `linear-gradient(
    to right,
    #EC0C92 0%,
    #1820D9 25%,
    #1C92FF 50%,
    #00FFE0 75%,
    #EC0C92 100%
  )`,
}}
aria-hidden="true"
/>
<Button
onClick={continueClicked}
disabled={disabled}
className={cn(
  'relative rounded-full bg-[#102087] px-8 py-7 text-xl font-light text-white hover:bg-[#102087]/80',
  'focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2',
  'w-full',
)}
>
Continue
</Button>
</div> */

// ---
// import { Button as ReactButton } from '@/components/ui/button';
// import { cn } from '@/lib/utils';

// type Props = {
//   class?: string;
//   href?: string;
//   gradient?: boolean;
//   targetBlank?: boolean;
//   ariaLabel?: string;
//   role?: string;
// };

// const {
//   class: className,
//   href,
//   gradient = false,
//   targetBlank = false,
//   ariaLabel,
//   role = 'button',
//   ...props
// } = Astro.props;

// const defaultClassName =
//   'rounded-full bg-black px-8 py-7 text-xl font-light text-white hover:bg-black/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500';

// // Define the gradient animation style inline
// const gradientStyle = {
//   '--gradient-animation': `linear-gradient(
//     to right,
//         #EC0C92 0%,
//         #1820D9 25%,
//         #1C92FF 50%,
//         #00FFE0 75%,
//         #EC0C92 100%
//   )`,
// };
// ---

// <div class="relative">
//   {
//     gradient ? (
//       <div
//         class="relative overflow-hidden rounded-full p-[3px]"
//         role="presentation"
//       >
//         <div
//           class="absolute inset-[-3px] animate-[border-shine_4s_linear_infinite] rounded-full"
//           style={{
//             background: gradientStyle['--gradient-animation'],
//             backgroundSize: '200% auto',
//           }}
//           aria-hidden="true"
//         />
//         <ReactButton
//           className={cn(defaultClassName, 'relative', className)}
//           client:load
//           aria-label={ariaLabel}
//           role={role}
//           {...props}
//         >
//           {href ? (
//             <a
//               href={href}
//               target={targetBlank ? '_blank' : undefined}
//               rel={targetBlank ? 'noopener noreferrer' : undefined}
//               class="block w-full"
//               aria-label={ariaLabel}
//             >
//               <slot />
//             </a>
//           ) : (
//             <slot />
//           )}
//         </ReactButton>
//       </div>
//     ) : (
//       <ReactButton
//         className={cn(defaultClassName, className)}
//         client:load
//         aria-label={ariaLabel}
//         role={role}
//         {...props}
//       >
//         {href ? (
//           <a
//             href={href}
//             target={targetBlank ? '_blank' : undefined}
//             rel={targetBlank ? 'noopener noreferrer' : undefined}
//             class="block w-full"
//             aria-label={ariaLabel}
//           >
//             <slot />
//           </a>
//         ) : (
//           <slot />
//         )}
//       </ReactButton>
//     )
//   }
// </div>

// <style>
//   @keyframes border-shine {
//     0% {
//       background-position: 0% center;
//     }
//     100% {
//       background-position: 200% center;
//     }
//   }
// </style>

// From tailwind.config.ts
//  keyframes: {
//   'accordion-down': {
//     from: { height: '0' },
//     to: { height: 'var(--radix-accordion-content-height)' },
//   },
//   'accordion-up': {
//     from: { height: 'var(--radix-accordion-content-height)' },
//     to: { height: '0' },
//   },
//   'caret-blink': {
//     '0%,70%,100%': { opacity: '1' },
//     '20%,50%': { opacity: '0' },
//   },
//   'border-shine': {
//     from: {
//       backgroundPosition: '0% center',
//     },
//     to: {
//       backgroundPosition: '200% center',
//     },
//   },
// },
// animation: {
//   'accordion-down': 'accordion-down 0.2s ease-out',
//   'accordion-up': 'accordion-up 0.2s ease-out',
//   'caret-blink': 'caret-blink 1.25s ease-out infinite',
//   'border-shine': 'border-shine 4s linear infinite',
// },
// screens: {
//   short: { raw: '(max-height: 700px)' },
// },
// },
// },
// } satisfies Config;
